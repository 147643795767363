import React from 'react';
import Step from '../components/Step'
import { rules } from '../data/rules';
import '../styles/rules.css'

const Rules = () => {
    return (
        <section id="rules">
            <div className='rules'>
                <h2>Zasady akcji</h2>
                <div className='steps'>
                    {
                        rules.map((el,ind)=>{
                            return <Step key={ind} {...el}/>
                        })
                    }
                </div>
            </div>
        </section>
    );
};

export default Rules;