import React from 'react';

const Item = ({story,img}) => {
    return (
        <div className='item'>
            <div className='item_img'>
                <img src={img} alt="img" />
            </div>
            {/* <p className='name'>{itemName}, lat {years}:</p> */}
            <p className='item_desc'>{story}</p>
        </div>
    );
};

export default Item;