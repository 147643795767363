import React from 'react';
import '../styles/footer.css'
import Logo from '../components/Logo';
import Pdf from '../assets/Regulamin_AkcjaRehabilitacja.pdf';

const Footer = () => {
    return (
        
        <section>
            <img src="../assets/contributors.jpg" alt="contributors" width="100%"/>
            <footer>
                <div className="footer_wrapper">
                    <div className="footer_logos">
                        <img src="../assets/logo_akcja.png" alt="akcja rehabilitacja" width={206}/>
                        <Logo/>
                    </div>
                    <div className='footer_nav'>
                        <div className='footer_information'>
                            <p>Informacyjne</p>
                            <ul>
                                <li><a href={Pdf} target="_blank" rel="noopener noreferrer">Regulamin</a></li>
                                <li><a href="https://17milionow.pl/pl/" target="_blank" rel="noopener noreferrer">#17milionów</a></li>
                                <li><a href="#about">O turnusie</a></li>
                            </ul>
                        </div>
                        <div className='footer_contact'>
                            <p>Jeśli masz pytania:</p>
                            <a href="mailto:kontakt@akcjarehabilitacja.org">kontakt@akcjarehabilitacja.org</a>
                        </div>
                    </div>
                </div>
                <div className='copyrights'>
                    <p>Copyright 2022, Wszystkie prawa zastrzeżone.</p>
                </div>
            </footer>
        </section>
    );
};

export default Footer;