import React from 'react';

const Button = ({color,children,click,style,type,disabled}) => {

    const styles = {...style,backgroundColor:color === 'primary' ? '#ffe807' : '#eb1784',textTransform:'uppercase'}
    return (
        <>
          <button className={`btn ${disabled ? `-disabled` : ``}`}
            style={styles}
            onClick={click}
            type={type !== null ? type : 'button'}
          >
            {children}
          </button>  
        </>
    );
};

export default Button;