import React from 'react';
import ReactDOM from 'react-dom';

const Burger = ({setNav,toggle}) => setNav ? ReactDOM.createPortal(

    <React.Fragment>
            <div className="burger_overlay" style={{display: setNav ?  'block' : 'none'}}>
                <aside className='burger_wrapper'>
                    <button onClick={toggle}>X</button>
                    <div className="burger_content">
                        <ul>
                            <li><a href="#about">O akcji</a></li>
                            <li><a href="#rules">Zasady</a></li>
                            <li><a href="#form">Formularz</a></li>
                            <li><a href="#contact">Kontakt</a></li>
                        </ul>
                    </div>          
                </aside>
            </div>
    </React.Fragment>,document.body
): null
export default Burger;