import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/modal.css'

const Modal = ({isShowing,hide,children,customStyle}) => isShowing ? ReactDOM.createPortal(
    <React.Fragment>
        <div className="modal_overlay">
            <div className="modal_wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className='modal' style={customStyle}>
                    <span className='modal_close' style={customStyle && {color:'white'}}  onClick={hide}>x</span>
                    <div className="modal_box">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>,document.body
) : null;

export default Modal;