import React from 'react';

const Step = ({step,info,desc,path,clickUrl}) => {

    return (
        <div className='step_item'>
            {clickUrl !== "" ? 
                    <a href={clickUrl} target="_blank" rel="noopener noreferrer">
                        <img src={path} alt={desc} className='step_img'/>
                    </a> : 
                    <img src={path} alt={desc} className='step_img'/>
            }
            <div className='step_desc'>
                <h3>Krok {step}: {info}</h3>
                <p>{desc}</p>
            </div>   
        </div>
    );
};

export default Step;