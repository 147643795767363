import {initializeApp} from 'firebase/app';
import {getStorage} from "firebase/storage";
import {getFirestore} from '@firebase/firestore';

const firebaseConfig = {

  apiKey: "AIzaSyDtWjMmRX6eVmsSl83gFWCaDag3ScsXPHg",

  authDomain: "rehabilitacja-c2ea8.firebaseapp.com",

  projectId: "rehabilitacja-c2ea8",

  storageBucket: "rehabilitacja-c2ea8.appspot.com",

  messagingSenderId: "360400917476",

  appId: "1:360400917476:web:cc45aad967f2d396e7adf1"

};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
