
export const rules = [
    {
        step: 1,
        info: 'Odpowiedz na krótkie pytanie -',
        desc: 'chcemy Was lepiej poznać',
        path: '../assets/conversation.png',
        clickUrl: ""

    },
    {
        step: 2,
        info: 'Napisz nam waszą historię -',
        desc: 'jak dzielnie walczycie każego dnia o sprawność i poprawę funkcjonowania',
        path: '../assets/contract.png',
        clickUrl: ""

    },
    {
        step: 3,
        info: 'Dodaj zdjęcie',
        desc: 'By uczestnicy wyzwania #17milionówMetrów mogli zobaczyć małego bohatera (opcjonalnie)',
        path: '../assets/portraits.png',
        clickUrl : ""

    },
    {
        step: 4,
        info: 'Ty też możesz zostać jurorem',
        desc: '',
        path: '../assets/footprints.png',
        clickUrl: "https://17milionow.pl/pl/strona-glowna/wyzwanie-sportowe-17milionowmetrow"

    }
]