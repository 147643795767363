import React from 'react';
import useModal from '../hooks/useModal';
import '../styles/about.css'
import Button from '../components/Button';
import Modal from '../components/Modal';

const About = () => {
    const {isShowing,toggle} = useModal();

    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

    return (
        <section id="about">
            <div className='content_wrapper'>
                <article>
                    <hgroup>
                        <h1>Pomoc</h1>
                        <p>&nbsp;w codziennym życiu <br/>
                            <span className='underTitle'>dla niezwykłych bohaterów</span>
                        </p> 
                    </hgroup>
                    <div className='description'>
                        <p>
                            Z okazji <b>Światowego Dnia Mózgowego 
                            Porażenia Dziecięcego</b> firma Nutricia - partner 
                            główny <b>wyzwania sportowego #17milionówMetrów</b>, 
                            ufundował <b>1 turnus rehabilitacyjny dla dzieci</b> ze zdiagnozowanym Mózgowym Porażeniem Dziecięcym.
                        </p>
                    </div> 
                    <div className='btn_container'>
                        <Button color='primary' click={toggle} style={{marginLeft:'20px'}}>O Turnusie</Button>
                        <Button color='primary' click={() => openInNewTab("https://17milionow.pl/pl/strona-glowna/wyzwanie-sportowe-17milionowmetrow")} style={{marginLeft:'20px'}}>Dołącz do wyzwania #17milionówmetrów</Button>
                    </div>
                </article>
                <Modal
                    isShowing={isShowing}
                    hide={toggle}
                >
                    <h4 className='modal_topic'>O turnusie</h4>
                    <p style={{fontWeight:'bold'}}>Turnus rehabilitacyjny dla dzieci z mózgowym porażeniem dziecięcym (5 dniowy) zawierający 15 godzin zajęć terapeutycznych realizowanych przez terapeutów Fundacji Kolorowy Świat w Łodzi w siedzibie fundacji na ul. Żabiej 10/12.</p>
                    <p>                 
                        Podstawowym założeniem realizowanego turnusu dla dzieci z mózgowym porażeniem dziecięcym jest holistyczne podejście do potrzeb małego pacjenta. Dlatego też podczas turnusu zwrócona jest szczególna uwaga na poprawę funkcjonowania dziecka na poziomie motoryki dużej i małej, wzmacniana jest jego pewność siebie i niezależność dzięki ćwiczeniom samoobsługowym, a także zapewniona ekspresja własnych potrzeb poprzez pracę nad komunikacją z otoczeniem.
                    </p>
                    <p>
                        Turnus rozpoczyna się oceną dziecka pod względem jego najważniejszych potrzeb z zakresu motoryki dużej, małej, samoobsługi oraz mowy i funkcji poznawczych. Przeprowadzany jest dokładny wywiad z rodzicem na temat największych ograniczeń dziecka oraz dedykowane małemu pacjentowi testy funkcjonalne w zakresie czynności samoobsługowych, ocenę GMFM (Gross Motor Function Measure), obserwację kliniczną z zakresu Integracji Sensorycznej i terapii logopedycznej. W oparciu o zebrane informacje wyznaczane są cele terapeutyczne za pomocą skali GAS (Goal Attainment Scaling). Turnus w głównej mierze oparty jest na pracy metodą NDT-Bobath, podczas której fizjoterapeuci Fundacji Kolorowy Świat pracują z pacjentem specjalnie dostosowanymi technikami stymulacji proprioceptywnej. W kolejnej części turnusu pacjent pracuje z logopedą lub neurologopedą w zależności od potrzeb nad mową, komunikacją alternatywną lub nad poprawą jakości spożywania posiłków.
                    </p>
                </Modal>
            </div>
        </section>
    );
};

export default About;