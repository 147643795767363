import React, { useEffect, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import Item from '../components/Item';
import '../styles/gallery.css'



const Gallery = ({formList,injectDocs,active}) => {
    const [itemToShow,setItemToShow] = useState(6);
    const [itemsToScroll,setItemToScroll] = useState(3);

    const showItem = () => {
        if(window.innerWidth <= 1600){
            if(window.innerWidth <= 633){
                setItemToShow(1)
                setItemToScroll(1);    
            }else{
                if(window.innerWidth >= 860){
                    setItemToShow(3);
                    setItemToScroll(3);
                }else{
                    setItemToShow(2);
                    setItemToScroll(2);
                }
                
            }
        }else{
            setItemToShow(6);
            setItemToScroll(3);
        }
        
        
    }

    useEffect(()=>{
        showItem()
    },[])


    window.addEventListener('resize',showItem)

    if(active){
        return (
            <section id="contact">
                <h2>Galeria niezwykłych bohaterów</h2>
                <Carousel  itemsToShow={itemToShow} enableMouseSwipe={false} enableSwipe={true} onNextStart={injectDocs} itemsToScroll={itemsToScroll}>
                    {formList.map((item,ind) => <Item key={ind} story={item.story} img={item.imgPath}/>)}
                </Carousel>
            </section>
        );
    }else{

    

    return (
        <section id="contact">
            <h2>Galeria niezwykłych bohaterów</h2>
            <div className='alternativeGallery'>
                {formList.map((item,ind) => <Item key={ind} story={item.story} img={item.imgPath}/>)}
            </div>
        </section>
    );

    }
  
};

export default Gallery;