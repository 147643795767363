import React, { useEffect, useState } from 'react';
import '../styles/header.css'
import Logo from '../components/Logo';
import Burger from '../components/Burger';

const Header = () => {
    const [backgroundNav,setbackgroundNav] = useState(false)
    const [showBurger,setShowburger] = useState(false);
    const [setNav,setShowNav] = useState(false);

    useEffect(()=>{
        showBackgroundNav();
        setBurger();
    },[])

    const showBackgroundNav = () =>{
        if(setNav){
            setShowNav(!setNav);
        }
        setbackgroundNav(window.scrollY >= 200 ? true : false);
    }

    const setBurger = () => {
        if(window.innerWidth <= 1125){
            setShowNav(false)
            setShowburger(true);
        }else{
            setShowburger(false)
        }
        
        
    }

    window.addEventListener('scroll',showBackgroundNav)
    window.addEventListener('resize',setBurger)

    
    const handleOpen = e => {
        e.preventDefault();
        setShowNav(!setNav)
    }

    useEffect(()=>{

    },[setNav])


    return (
        <section id="header">
            <header>
                <nav className={`${backgroundNav ? 'active' : ''} ${setNav ? 'opened' : ''}`}>
                    <Logo/>
                    {showBurger ? <button onClick={handleOpen}><img src="./assets/burger.png" alt="hamburger menu" width="20" height="20" /></button> :
                        <ul className='nav'>
                            <li className='nav_element'><a href="#about">O akcji</a></li>
                            <li className='nav_element'><a href="#rules">Zasady</a></li>
                            <li className='nav_element'><a href="#form">Formularz</a></li>
                            <li className='nav_element'><a href="#contact">Kontakt</a></li>
                        </ul>
                    }
                    
                </nav>
                <Burger setNav={setNav} toggle={handleOpen}/>  
            </header>
            
        </section>
    );
};

export default Header;