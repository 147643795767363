import { useEffect, useState } from 'react';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  useEffect(()=>{
    if(isShowing){
        document.body.style.overflowY = 'hidden'
    }else{
        document.body.style.overflowY = 'auto'
    }
  },[isShowing])

  function toggle() {
    setIsShowing(!isShowing);
    
  }

  return {
    isShowing,
    toggle,
  }
};

export default useModal;