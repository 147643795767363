import React from 'react';
import logo from '../assets/logo.png'

const Logo = () => {
    return (
        <div className="logo">
            <a href='#header'><img src={logo} alt='17milionow' height={31} width={206} draggable={false}/></a>
        </div>
    );
};

export default Logo;