import React,{ useEffect, useState} from 'react';
import Header from "./sections/Header";
import About from "./sections/About"
import Rules from "./sections/Rules";
import Form from "./sections/Form";
import Gallery from "./sections/Gallery";
import useModal from './hooks/useModal';
import Modal from './components/Modal';
import Footer from './sections/Footer';
import {db} from './firebase'
import {collection,getDocs, limit, orderBy,query, startAfter} from 'firebase/firestore';



function App() {
  const [formList,setFormList] = useState([])
  const [responseLast,setResponseLast] = useState({})
  const dataRef = collection(db,"kids")
  const {isShowing,toggle} = useModal();

  const addListItem = (newItem) =>{
    const newList = [newItem,...formList];
    setFormList(newList)
  }



  useEffect(()=>{    
    const getData = async () => {
        const data = await getDocs(query(dataRef,orderBy('creationDate','desc')))
        setFormList(data.docs.map((doc)=>({...doc.data(),id : doc.id})))
        setResponseLast(data.docs[data.docs.length - 1])
    };
    getData();
    // toggle();
  },[])

  const injectDocs = async () => {
    const data = await getDocs(query(dataRef,orderBy('creationDate','desc'),startAfter(responseLast.data().creationDate),limit(6)))
    if(data.docs.length > 0){
      const tempArr = data.docs.map((doc)=>({...doc.data(),id : doc.id}))
      setFormList([...formList,...tempArr])
      setResponseLast(data.docs[data.docs.length-1]);
    }
  }

  return (
    <div className="App">
      <Modal isShowing={isShowing} hide={toggle} customStyle={{backgroundColor:"#98C02B",padding:"10px"}}>
        <img src="./assets/logo_akcja.png" alt="akcja_rehabilitacja_dobiegła_końca" width="70%" style={{marginTop:'30px'}}/>
        <p className='end_modal_topic'>dobiegła końca</p>
        <p className="end_modal_info">Dziękujemy bardzo za wszystkie zgłoszenia. Tegoroczna Akcja rehabilitacja została zakończona:</p>
        <ul className='end_winner_list'>
          <li>
            <img src="./assets/inbound1047733446523388251-modified.png" alt="" className="end_winner_img" />
            <p>Staś</p>
          </li>
          <li>
            <img src="./assets/4660-20211023191637-CAuJ-large-modified.png" alt="" className="end_winner_img" />
            <p>Monika</p>
          </li>
          <li>
            <img src="./assets/inbound4850617335058900152-modified.png" alt="" className="end_winner_img"  />
            <p>Antoni</p>
          </li>
          <li>
            <img src="./assets/inbound4850617335058900152-modified.png" alt="" className="end_winner_img"  />
            <p>Emilka</p>
          </li>
        </ul>
      </Modal>
      <Header/>
      <About/>
      <Rules/>
      <Form addListItem={addListItem}/>
      <Gallery formList={formList} injectDocs={injectDocs} active={false}/>      
      <Footer/>
    </div>
  );
}

export default App;
