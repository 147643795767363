import React,{useState} from 'react';
import '../styles/form.css'
import Button from '../components/Button';
import Pdf from '../assets/Regulamin_AkcjaRehabilitacja.pdf';
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { storage,db } from "../firebase";
import {collection, addDoc,Timestamp,getDocs,query,where} from "firebase/firestore";
import {v4} from 'uuid';
import useModal from '../hooks/useModal';
import Modal from '../components/Modal';

const Form = ({addListItem}) => {

    const [selectedFile,setSelectedFile] = useState(null);
    const [celebralPalsy,setCelebralPalsy] = useState()
    const {isShowing,toggle} = useModal();
    const [alert,setAlert] = useState("")
    const [modalTopic,setModalTopic] = useState('Dziękujemy za zgłoszenie.')

    const [inputs,setInputs] = useState({email : "", phone : "", story : "",imgPath : null, creationDate : null});
    const dataRef = collection(db,"kids");

    const handleFile = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs(prevInput => ({
            ...prevInput,
            [name]: value
        }));
    };

    const clearInputs = () =>{
        setSelectedFile(null);
        setInputs({email : "", phone : "", story : "",imgPath : null, creationDate : null});
        document.querySelector('input[name=agreement_1]').checked = false;
        document.querySelector('input[name=agreement_2]').checked = false;
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        if(true){
            setModalTopic('Dziękujemy za zgłoszenie.')
            if(selectedFile == null) return;
            if(celebralPalsy == 0){
                setAlert("Akcja Rehabilitacja jest przeznaczona dla dzieci ze zdiagnozowanym Mózgowym Porażeniem Dziecięcym")
                toggle();
                clearInputs();
                return;
            }else{
                setAlert("Twoje zgłoszenie zostało zarejestrowane. Po zakończeniu wyzwania #17milionówmetrów 3 osoby z najwyższym wynikiem dokonają wyboru 3 dzieci, które będą uczestniczyć w „bezpłatnym turnusie rehabilitacyjnym dla dzieci ze zdiagnozowanym MPD”")
            }
            const imgPathName = selectedFile.name + v4();
            const imageRef = ref(storage, `images/${imgPathName}`);
            getDocs(query(dataRef,where('phone','==',inputs.phone))).then((res)=>{
                if(res.docs.length === 0){
                    uploadBytes(imageRef, selectedFile).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then((url) => {
                            addDoc(dataRef,({...inputs,  imgPath : url, creationDate : Timestamp.fromDate(new Date()).toDate()})).then(()=>{
                                addListItem({...inputs, imgPath : url})
                                toggle();
                                clearInputs();
                            }) 
                
                        })
                    });
                }
                else{
                    setModalTopic('Wystąpił błąd.')
                    setAlert('Zgłoszenie na podany adres telefonu zostało już dodane.')
                    toggle();
                }
            });
        }     
    }

    return (
            <section className='sendForm' id='form'>
                <form onSubmit={handleSubmit}>
                    <h2>Formularz zgłoszeniowy</h2>  
                    <div className="cerebral_palsy_agreements">
                        <p>Czy Twoje dziecko ma zdiagnozowane Mózgowe Porażenie Dziecięce?</p>
                        <label htmlFor="cerebral_palsy_yes" className='label_above'>
                            <p>TAK</p>
                            <input type="radio" id="cerebral_palsy_yes" name="cerebral_palsy" value={1} onChange={(e) => setCelebralPalsy(e.target.value)} disabled={false}/>
                        </label>
                        <label htmlFor="cerebral_palsy_no" className='label_above'>
                            <p>NIE</p>
                            <input type="radio" id="cerebral_palsy_no" name="cerebral_palsy" value={0} onChange={(e) => setCelebralPalsy(e.target.value)} disabled={false}/>
                        </label>
                    </div>
                    <label htmlFor='story'>Wasza historia:</label>
                    <textarea id='story' name='story' rows="9" cols="72" maxLength="200" className='sendForm_story' placeholder='Opisz Waszą historię, wiek dziecka, imię itd.' onChange={handleChange} value={inputs.story} disabled={false}></textarea>
                    <label htmlFor="story_file" className="file_btn ">Dodaj zdjęcie</label>
                    <input type="file" id="story_file" style={{visibility:'hidden'}}  accept='image/png, image/jpeg' onChange={handleFile} disabled={false}/>
                    <div className='contact'>
                        <label htmlFor="email">Wpisz swój adres email:<br/>
                            <input type="email" id="email" name="email" placeholder='Email' onChange={handleChange} value={inputs.email} disabled={false}/>
                        </label>
                        <label htmlFor="phone">Wpisz swój numer telefonu:<br/>
                            <input type="text" id="phone" name="phone" placeholder='Numer telefonu'  onChange={handleChange} value={inputs.phone} disabled={false}/>
                        </label>
                    </div>
                    <p>Zgody marketingowe:</p>
                    <div className="agreements">
                        <input type="checkbox" className='story_checkbox' name="agreement_1" required disabled={false}/>
                        <label htmlFor="agreement_1">Oświadczam, że zapoznałam/łem się z treścią <a href={Pdf} target="_blank" rel='noopener noreferrer'>regulaminu</a> aktywacji wraz z informacją na temat przetwarzania danych osobowych i akceptuję jego treść.</label>
                        <br/><br/>
                        <input type="checkbox" className='story_checkbox' name="agreement_2" required disabled={false}/>
                        <label htmlFor="agreement_2">Oświadczam, że wyrażam zgodę na umieszczenie zdjęć zawierających wizerunek mojego dziecka (imię i nazwisko dziecka) zarejestrowanych podczas akcji skierowanej do rodziców i opiekunów pt. "Akcja Rehabilitacja", a także w mediach w celu informacji i promocji przeprowadzonych działań.</label>
                    </div>
                    <p className='regulations'>
                        Administratorem Państwa danych osobowych zawartych w formularzu jest Nutricia Polska sp. z o.o. z siedzibą w Warszawie przy ul. Bobrowieckiej 8, 00-728 Warszawa. Państwa dane będą przetwarzane w celach bezpośrednio związanych z przeprowadzeniem akcji pn. „Akcja Rehalibitacja”. Mają Państwo prawo żądania dostępu do treści swoich danych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do przenoszenia danych, prawo do wniesienia sprzeciwu wobec przetwarzania oraz prawo wniesienia skargi do organu nadzorczego. Mają Państwo prawo do cofnięcia zgody na przetwarzanie danych w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na jej podstawie przed jej cofnięciem. Szczegółowe informacje na temat przetwarzania Państwa danych znajdują się w regulaminie akcji. 
                    </p>
                    <Button type="submit" style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'300px',margin:'40px auto'}} disabled={false}><img src="../assets/send.png" width={24} height={24} alt="send.png" aria-disabled={false}/>Wyślij zgłoszenie</Button>
                </form>
                <Modal
                    isShowing={isShowing}
                    hide={toggle}
                >
                  <h4 className='modal_topic'>{modalTopic}</h4>
                  <p>{alert}</p> 
                </Modal>
            </section>
    );
};

export default Form;